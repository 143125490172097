import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Navbar } from "./components/Navbar";
import { Home } from "./components/Home";
import PrivacyPolicy from "./components/PrivacyPolicy";

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Home />}></Route>
          <Route
            exact
            path="/privacyPolicy"
            element={<PrivacyPolicy />}
          ></Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
