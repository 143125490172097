import React from "react";

export const Home = () => {
  return (
    <div
      style={{
        marginLeft: 250,
        marginRight: 250,
        marginTop: 40,
        marginBottom: 40,
      }}
    >
      <h2 style={{ textAlign: "center" }}>Welcome</h2>
      <p>
        Accomplish app lets you create customized goals and allows you to track
        and monitor in order to make sure you accomplish them on time.
      </p>
      <p>
        Now that you're here find out few of the many things you can do in the
        Accomplish app.
      </p>
      <h6>Goals</h6>
      <ul>
        <li>
          Create Goals based on category i.e. Music, Fitness, Learning, Art etc.
        </li>
        <li>Set a start date from the current day or set one for the future</li>
        <li>Provide an End date or Total duration in hours</li>
        <li>Set the hours per day you wish to spend for a particular goal</li>
      </ul>
      <h6>Goal Details</h6>
      <p>
        The goals details tabs let you view the basic information regarding the
        goals i.e. Starting date, Confirmed or Probable ending date, Hours to be
        spent per day.
      </p>

      <h6>Tracking</h6>
      <p>
        The app has an analytics view that displays charts that would provide
        you with day-to-day progress of your on going goals.
      </p>
      <p>
        With the charts, compare your present week's progress to your last
        week's and your current month's progress to your last month's to get an
        idea on how far you'e come in terms of accomplishing the goals that you
        have created
      </p>
      <p>
        Also, get notified about the goals in progress and be reminded about the
        pending goals to be marked for the current day.
      </p>
      <p style={{ textAlign: "center" }}>
        Get, Set, <b>Accomplish!!!</b>
      </p>
    </div>
  );
};
